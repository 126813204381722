































































import Vue from 'vue'
import PicklItem from '@/components/common/PicklItem.vue'
import HollowCard from '@/components/common/HollowCard.vue'
import PicklDetails from '@/components/common/PicklDetails.vue'
import DeletePicklPopup from '@/components/SAdmin/DeletePicklPopup.vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import AddressesListPopup from '@/components/SAdmin/AddressesListPopup.vue'
import PopPickl from '@/components/common/PopPickl.vue'

@Component({
  components: {
    HollowCard,
    PicklItem,
    PicklDetails,
    DeletePicklPopup,
    AddressesListPopup,
    PopPickl
  }
})
export default class PopTasks extends Vue {
  public active = 0
  public stores:any = []
  public brands:any[] =[]
  public user:any = {}
  public activePickl = {}
  public statuses:any[] = []
  public pickls:any = []
  public limitPerPage: number = 100
  public rowsOffset: number = 0
  public brandId:any = ''
  public locationId = ''
  public storeLocationId:any = ''
  public loadingPickls = true
  public hasMorePickls = false
  public storeLimitPerPage:number = 1000
  public storeOffset:number = 0
  public storeLocationLimitPerPage:number = 1000
  public storeLocationOffset:number = 0
  public brandLimitPerPage:number = 1000
  public brandOffset:number = 0
  public storeName: string = ''
  public storeLocations:any[] =[]
  public state:string = ''
  public timer:number = 0
  public states:any = []
  public stateId: string =''
  public countries:any = []
  public countryId:string = ''
  public selectedPicklId: number = 0
  public deletedPickls: any[] = []
  public userRole: any = 'staff'
  public userUrl:any = 'staff'
  public sort:any = 'expire_date_time-ASC'
  mounted () {
    this.getUserPicklCount()
    this.getStores()
    this.getBrands()
    this.getCountries()
    // this.getStates()
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  }
  getCountries () {
    this.$http.get('countries?service_enabled=true').then((response:any) => {
      this.countries = response.body.data
    })
  }

  getStatesByCountry () {
    this.updateList()
    if (this.countryId !== '') {
      this.$http.get('states?country_id=' + this.countryId).then((response: any) => {
        this.states = response.body.data
      })
    } else {
      this.states = []
    }
  }

  getUserPicklCount () {
    this.loadingPickls = true
    this.$http
      .get('pickls/status-count')
      .then((response:any) => {
        this.statuses = response.body.data
        this.loadingPickls = false
        let status = this.$route.query['status']
        if (status) {
          for (let i = 0; i < this.statuses.length; ++i) {
            if (this.statuses[i].status === status) {
              this.active = i
              break
            }
          }
        }
        this.getPickls()
      }, response => {
        this.loadingPickls = false
      })
  }
  getPickls () {
    this.loadingPickls = true
    let params = { 'limit': this.limitPerPage, 'offset': this.rowsOffset, 'status': this.statuses[this.active].status, 'storeLocationId': this.storeLocationId, 'brandId': this.brandId, countryId: this.countryId, 'stateId': this.stateId, 'sort': this.sort }
    this.$http
      .get('pickls', { params: params })
      .then((response: any) => {
        if (this.limitPerPage === params.limit && this.rowsOffset === params.offset && params.status === this.statuses[this.active].status && this.storeLocationId === params.storeLocationId && this.brandId === params.brandId) {
          this.rowsOffset += this.limitPerPage
          this.pickls.push(...response.body.data)
          if (this.pickls.length === response.body.count) {
            this.hasMorePickls = false
          } else {
            this.hasMorePickls = true
          }
          this.loadingPickls = false
        }
      }, response => {
        this.loadingPickls = false
      })
    this.$router.push({ query: { status: this.statuses[this.active].status } })
  }
  getStores (clear: boolean = true): void {
    this.$store.dispatch('getStores', { limit: this.storeLimitPerPage, offset: this.storeOffset, sort: 'name-ASC' }).then((response: any) => {
      this.stores.push(...response.response.body.data)
      this.storeOffset += this.storeLimitPerPage
      if (this.storeOffset < response.response.body.count) {
        this.getStores()
      }
    })
  }

  getBrands (clear: boolean = true): void {
    this.$store.dispatch('getBrands', { limit: this.brandLimitPerPage, offset: this.brandOffset }).then((response: any) => {
      this.brands.push(...response.response.body.data)
      if (this.brands.length < response.response.body.count) {
        this.brandOffset += this.brandLimitPerPage
        this.getBrands()
      }
    })
  }
  updatePicklsList (index:number) {
    this.active = index
    if (this.active === 1 || this.active === 0) {
      this.sort = 'expire_date_time-ASC'
    } else if (this.active === 2) {
      this.sort = 'updated_at-ASC'
    } else if (this.active === 3) {
      this.sort = 'updated_at-DESC'
    } else {
      this.sort = 'updated_at-DESC'
    }
    this.updateList()
  }
  updateBrandId (brandId:number) {
    this.updateList()
  }
  updateStoreLocationId (storeLocationId: any) {
    if (this.locationId === '') {
      this.storeLocationId = this.storeLocations.map(location => location.id).join(',')
    } else {
      this.storeLocationId = this.locationId
    }
    if (this.storeLocations.length > 0) {
      this.updateList()
    } else {
      this.clearList()
    }
  }

  clearList () {
    this.pickls = []
    this.rowsOffset = 0
    this.hasMorePickls = false
  }

  updateList () {
    this.clearList()
    this.getPickls()
  }
  updatePicklsCount () {
    this.pickls = []
    this.rowsOffset = 0
    this.hasMorePickls = false
    this.getUserPicklCount()
  }

  getStoreLocations (storeId:any, reset:boolean = true) {
    this.locationId = ''
    this.storeLocationId = ''
    if (storeId === '') {
      this.updateList()
      this.storeLocations = []

      return false
    }
    if (reset) {
      this.storeLocations = []
      this.storeLocationLimitPerPage = 1000
      this.storeLocationOffset = 0
    }
    let q = { sort: 'id-ASC', storeId: storeId, 'limit': this.storeLocationLimitPerPage, offset: this.storeLocationOffset }
    this.$http
      .get('store_locations', { params: q })
      .then((response: any) => {
        if (this.storeLocationLimitPerPage === q.limit && this.storeLocationOffset === q.offset) {
          this.storeLocationOffset += this.storeLocationLimitPerPage
          this.storeLocations.push(...response.body.data)
          if (this.storeLocationOffset < response.body.count) {
            this.getStoreLocations(storeId, false)
          } else {
            this.updateStoreLocationId(null)
          }
        }
      }
      )
  }
  resetListing () {
    this.stateId = ''
    this.locationId = ''
    this.storeLocationId = ''
    this.storeName = ''
    this.storeLocations = []
    this.brandId = ''
    this.getPickls()
  }

  getPicklDetails (id:number) {
    this.selectedPicklId = id
  }

  updatePicklOffset (deletedPicklId: any) {
    this.deletedPickls.push(deletedPicklId)
    // let closePicklDetailsPopupButton: HTMLElement = this.$refs.closePicklDetailsPopupButton as HTMLElement
    // closePicklDetailsPopupButton.click()
    this.rowsOffset = this.rowsOffset - 1
  }

  checkDeletedPIckls (picklId: any) {
    return this.deletedPickls.includes(picklId)
  }

  pushToPicklDetailsPage (picklId: any) {
    this.$router.push('/' + this.userUrl + '/pickls/' + picklId)
  }

  @Watch('stateId')
  onPropertyChanged (value: string, oldValue: string) {
    let context = this
    clearTimeout(this.timer)
    this.timer = setTimeout(function () {
      context.updateList()
    }, 500 || 0)
  }
}
